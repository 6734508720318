.advertisingFullWrapper {
    min-height: 160px;
    margin-top: 72px;
    margin-bottom: 72px;
    // min-width: 950px;
    background-color: #e5eee9;
    padding: 20px 0;
    box-sizing: border-box;
    order: 4;
    display: flex;
    align-items: center;


    & a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
	}

	& a img {
		max-width: 100%;
        max-height: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

    @media (max-width: 1279px) {
        width: calc(100% - 32px);
        order: 5;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.advertisingInfoWrapper {
    display: block;
    width: 100%;
    height: 160px;
    background-color: #e5eee9;
    order: 4;
    margin-top: 72px;

    & .imgWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 0;
        box-sizing: border-box;
    }

    & .imgWrapper img {
		max-width: 100%;
        max-height: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;
    }
}

.advertisingAside {
    min-width: 950px;
    min-height: 160px;
    margin-top: 80px;
    margin-bottom: 100px;
    background-color: #e5eee9;
    padding: 20px 0;
    box-sizing: border-box;
    order: 4;
    display: flex;
    align-items: center;

    display: flex;
    flex-direction: row;
    min-width: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    min-height: auto;

    & a {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;

        & img {
            display: block;
            margin: 0;
        }

        @media (max-width: 700px) {
            align-items: initial;
            justify-content: center;
        }
    }


}

@media (max-width: 1279px) {
    .advertisingAside {
        & .advertisingFullWrapper {
            width: 100%;
            order: 0;
        }
    }
}