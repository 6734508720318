.videoItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 70px;
    border-bottom: 1px solid grey;

    & h5 {
        width: 100%;
        font-size: 24px;
        color: #010000;
        font-weight: 700;
        font-family: "Open Sans";
        text-transform: uppercase;
        margin-bottom: 16px;
        line-height: 24px;
    }

    & h6 {
        width: 100%;
        font-size: 12px;
        color: #095930;
        font-weight: 700;
        font-family: "Open Sans";
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    & p {
        width: 100%;
        font-size: 14px;
        color: #000000;
        font-weight: 700;
        font-family: "Open Sans";
    }

    & p span {
        white-space: pre-line;
        font-weight: 400;
    }
}

.youtubeVideo {
    width: 100%;
    height: 530px;
}

.videoAnchor {
    display: block;
    margin-top: 50px;
    font-size: 16px;
    line-height: 24px;
    color: #095930;
    font-weight: 700;
    font-family: "Open Sans";
}