.recomendationTitle {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans";
    text-transform: uppercase;
    box-sizing: border-box;
    padding-left: 30px;
    position: relative;
    margin-bottom: 50px;
}

.recomendationTitle::before {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: #095930;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 6px;
    left: 0px;
}

.recomendationList {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    & li {
        box-sizing: border-box;
        position: relative;
        max-width: 400px;

        width: 100%;
        padding-left: 20px;
        padding-right: 16px;
        margin-bottom: 20px;

        @media (max-width: 850px) {
            min-height: auto;
            max-width: initial;
        }
    }

    & li .recomendationItemTitle__dataLink {
        display: block;
        font-size: 14px;
        font-family: "Open Sans";
        line-height: 14pt;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border: 0 solid #c1cec8;
        border-bottom-width: 1px;
        color: black;
    }

    & li .recomendationItemTitle__dataLink:hover {
        color: #505050;
    }

    & li .recomendationItemTitle__dataLink:last-child {
        border-width: 0px;
    }
}

.recomendationListFull {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    & li {
        width: 100%;
        padding-left: 20px;
        margin-bottom: 48px;
    }

    & li .recomendationItemTitle__dataLink {
        display: block;
        font-size: 14px;
        line-height: 23.33px;
        font-family: "Open Sans";
        padding-bottom: 12px;
        margin-bottom: 12px;
        border: 0 solid #c1cec8;
        border-bottom-width: 1px;
        color: black;
    }

    & li .recomendationItemTitle__dataLink:last-child {
        border-width: 0px;
    }
}

.recomendationTitle {
    width: 100%;
    font-size: 24px;
    font-family: "Open Sans";
    text-transform: uppercase;
    box-sizing: border-box;
    padding-left: 30px;
    position: relative;
    margin-bottom: 50px;
}

.recomendationTitle::before {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: #095930;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 6px;
    left: 0px;
}

.recomendationItemTitle {
    width: 100%;
    height: 16px;
    font-size: 12px;
    font-family: "Open Sans";
    position: relative;
    margin-bottom: 15px;

    &__text {
        padding: 2px 20px;
        margin-bottom: 0;
        margin-left: -20px;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        background-color: #f26e18;
    }
}

.recomendationViewmore {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #095930;
    position: absolute;
    top: calc(100% - 8px);

    &:hover {
        color: #00a748;
    }
}

.recomendationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & .recomendationItemTitle::before {
        top: 3px;
    }
}