.organizationPreviewList {
	display: flex;
	flex-wrap: wrap;

	& li {
		margin: 8px;
		margin-bottom: 16px;
		margin-top: 0;
	}
}

.clearPadding {
	padding: 0;
}

.listImg {
	width: auto;
	min-width: 320px;
}
