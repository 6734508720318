table {
    width: 100%;
    margin: 0 auto;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 24px;
}

caption {
    padding: 10px;
    border: 1px solid black;
}

tr {

}

td {
    padding: 10px;
    border: 1px solid black;
}

.listTables {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    font-family: "Open Sans";

    & li {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 5px;
        padding-left: 5px;
        border-right-width: 2px;
        border-right-style: solid;
        border-color: #e2e2e2;
        cursor: pointer;
        text-align: center;
    }
}

.admin_block {
    margin-bottom: 20px;
    font-family: "Open Sans";
    display: flex;
    justify-content: space-between;
}

.admin_h1 {
    text-align: center;
    font-size: 30px;
}

.form_h1 {
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    font-family: "Open Sans";
}

label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

select {
    margin-bottom: 15px;
}

.search {
    display: block;
}

.form-name {
    width: 100%;
    text-align: center;
    font-size: 30px;
}

.message-result {
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid black;
    padding: 10px;
}

.formik-form {
    & .hidden {
        display: none;
    }

    & .tooltip {
        margin-bottom: 5px;
        font-size: 12px;
        color: #345936;
    }

    & .form-control {
        width: 100%;
        margin-bottom: 20px;

        & label {
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 14px;
        }

        & input {
            width: 100%;
            background-color: #f8f8f8;
            padding: 2px 5px;
            border: 2px solid black;
            box-sizing: border-box;
        }

        & input[type="checkbox"] {
            width: 25px;
            height: 25px;
        }

        & input[type="file"] {
            width: auto;
            height: auto;
            border: 0;
            background: none;
            padding: 0;
        }

        & input:disabled {
            border: 0;
        }

        & textarea {
            width: 100%;
            height: 600px;
            padding: 10px;
            background-color: #f8f8f8;
            border: 2px solid black;
            box-sizing: border-box;
            line-height: 24px;
        }

        & .small {
            height: 100px;
        }

        & .medium {
            height: 200px;
        }

        & .array {
            display: flex;
            flex-direction: column;

            & .arrayItem {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;

                & button {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                }
            }
        }
    }

    & .preview {
        display: block;
        width: 300px;
        height: 30px;
        font-size: 16px;
        color: white;
        border: 0;
        background-color: #344559;
        margin-bottom: 10px;
        line-height: 30px;
        text-align: center;
    }

    & .submitButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & button {
            width: 300px;
            height: 30px;
            font-size: 16px;
            color: white;
            border: 0;
        }

        & button:last-child {
            background-color: #344559;
        }

        & button:first-child {
            background-color: #345935;
        }
    }
}

.AdminListPagination {
    display: flex;
    color: green;
    margin-top: 20px;
    margin-bottom: 20px;

    & li {
        width: 20px;
        // padding: 0 10px;
        cursor: pointer;
        text-align: center;
        border: 1px solid green;
        margin-right: 10px;

        &:first-child,
        &:last-child {
            display: none;
        }
    }

    & li a {
        display: block;
        width: 100%;
    }

    & li:hover {
        color: black;
    }

    & .selected {
        color: red;
    }

}
