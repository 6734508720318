.footerInformation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 65px;
    min-width: 320px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
}

.footerLinkList li {
    margin-bottom: 15px;
}

.footerLinkList li:last-child {
    margin-bottom: 0;
}

.footerLinkList li a {
    font-size: 14px;
    color: white;
    font-family: "Open Sans";
}

.footerLeftWrapper {
    margin-right: 100px;

    & div {
        margin-bottom: 50px;
    }

    & div img {
        width: 100%;
        height: 100%;
    }
}

.footerTitle {
    color: white;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: bold;
    margin-top: 20px;
}

.footerList {
    margin-top: 5px;
    & li {
        width: 100%;
        min-width: 320px;
        max-width: 450px;
        margin-bottom: 5px;
        color: white;
        font-size: 14px;
        font-family: "Open Sans";
    }

    & a {
        width: 100%;
        min-width: 320px;
        max-width: 450px;
        margin-bottom: 5px;
        color: white;
        font-size: 14px;
        font-family: "Open Sans";
    }

    & a:hover {
        color: #f3f3f3;
    }
}

.footer {
    width: 100%;
    min-width: 320px;
    // background: linear-gradient(rgba(3,165,80,1) 0%, rgba(10,148,71,1) 100%);
    padding-top: 25px;
    padding-bottom: 35px;
    margin-top: 140px;
    background-image: url('../../../public/img/main/fon-footer.jpg');
    background-repeat: round;
    background-size: auto;
}

.footerContainer {
    // padding-left: 447.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerContact {
    font-size: 14px;
    color: white;
    font-family: "Open Sans";
    margin-top: 20px;
    margin-bottom: 20px;

    & p {
        margin-bottom: 5px;
    }

    & p:last-child {
        margin-bottom: 0;
    }
}
