.arrowTop {
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    cursor: pointer;

    & img {
        width: 100%;
    }
}