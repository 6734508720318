.JournalList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: -10px;
    margin-bottom: 20px;

    & li {
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    & li a {
        text-transform: uppercase;
    }

    & .active {
        background-color: #00a652;
    }

    & .active a {
        color: white;
    }

    & .unactive {
        background-color: white;
    }

    & .unactive a {
        color: green;
    }
}

.articlesList {
    & .active {
        background-color: #00a652;
    }
}

.videoList {
    & .active {
        background-color: #a60055;
    }
}

.recomendationListCat {
    & .active {
        background-color: #00a652;
    }
}