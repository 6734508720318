.previewTitle {
	width: 100%;
	font-size: 24px;
	font-weight: bold;
	font-family: "Open Sans";
	text-transform: uppercase;
	box-sizing: border-box;
	padding-left: 30px;
	position: relative;
    margin-bottom: 50px;
    
    &::before {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: #095930;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 6px;
        left: 0px;
    }
}

.previewContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}