.TitleMain {
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    padding-left: 34px;
    font-size: 24px;
    font-weight: 700;
    font-family: "Open Sans";
    text-transform: uppercase;
    line-height: 24pt;

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: #095930;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 10px;
        left: 0px;
    }

    @media (max-width: 700px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
