.articles {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 70px;

	max-width: 1280px;
    min-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.articlesSpecialion {
	width: 100%;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 300;
	color: #095930;
	margin-top: -30px;
	text-align: right;

	&:hover {
		color: #00a748;
	}
}

.articlesTitle {
	width: 100%;
	font-size: 24px;
	font-weight: bold;
	font-family: "Open Sans";
	text-transform: uppercase;
	box-sizing: border-box;
	padding-left: 30px;
	position: relative;
	margin-bottom: 50px;

	&::before {
		content: '';
		display: block;
		border-radius: 50%;
		background-color: #095930;
		width: 14px;
		height: 14px;
		position: absolute;
		top: 6px;
		left: 0px;
	}
}

.articleList {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;

	& li {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 1007px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@media (max-width: 700px) {
		padding-left: 0;
		padding-right: 0;
		justify-content: space-around;
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: 100%;
	}
}

.articleListPreview {
	& li {
		height: 462px;
	}
}

.articleItem {
	width: 100%;
	height: 100%;
	max-width: 294px;
	padding-bottom: 70px;
	margin-right: 10px;

	& .firstLink {
		color: #000000;
		font-weight: 700;
		font-size: 16px;
		font-family: 'Open Sans';
		font-weight: bold;
		padding-bottom: 16px;
		border: 0px solid #c1cec8;
		border-bottom-width: 1px;
		margin-top: 16px;
	}

	& .links a {
		color: #000000;
		display: block;
		font-size: 14px;
		line-height: 14pt;
		font-family: 'Open Sans';
		padding-bottom: 16px;
		border: 0px solid #c1cec8;
		border-bottom-width: 1px;
		margin-top: 16px;
	}

	& .links a:last-child {
		border-bottom-width: 0;
		padding-bottom: 0;
	}

	& a:hover {
		color: #505050;
	}

	@media (max-width: 700px) {
		margin-right: 0;
	}
}

.articleImg {
	width: 100%;
	// height: 200px;

	// & img {
	// 	width: 100%;
	// 	height: 100%;
	// 	max-width: 100%;
	// 	max-height: 100%;
	// }
}

.articleTitle {
	position: relative;
	height: 36px;
	text-transform: uppercase;
	box-sizing: border-box;
	background-color: #f2f2f2;
	padding-left: 26px;
	font-size: 14px;
	font-family: "Open Sans";
	font-weight: bold;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&__text a {
		color: #000000;
		display: block;
		font-size: 14px;
		font-family: 'Open Sans';
		padding-bottom: 16px;
		border: 0px solid #c1cec8;
		margin-top: 16px;
	}

	&::before {
		display: block;
		content: '';
		width: 12px;
		height: 12px;
		background-color: #00a748;
		position: absolute;
		left: 0;
	}
}

.articleTitle__text:hover {
	color: #00a748;
}

.articleTitle__link {
	width: 36px;
	height: 100%;
	background-color: #e6e6e6;

	& a {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
	}

	& a:after {
		content: '';
		display: block;
		width: 10px;
		height: 18px;
		background-color: #404040;
		position: absolute;
		top: 50%;
		left: 50%;
		clip-path: polygon(100% 50%, 54% 0, 46% 0, 94% 50%, 46% 100%, 54% 100%);
		transform: translate(-50%, -50%);
	}
}
