.articlePage {
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    min-width: 320px;

    & .ArticlePageMain {
        width: 100%;
        max-width: 930px;
        margin-right: 25px;
        flex-shrink: 1;
    }

    & .articlePage__aside {
        flex-shrink: 0;
    }

    @media (max-width: 700px) {
        flex-direction: column-reverse;
        padding-right: 16px;
    }
}

.sectionLink {
    font-size: 12px;
    color: #095930;
    font-weight: 400;
    font-family: "Open Sans";
    text-transform: uppercase;
    display: block;
    margin-bottom: 30px;
}

.metaArticle {
    margin-bottom: 30px;

    &__journal {
        font-size: 12px;
        color: #929ca0;
        font-weight: 700;
        font-family: "Open Sans";
        margin-bottom: 10px;
        display: block;
    }

    &__code {
        font-size: 12px;
        color: #0b5731;
        font-weight: 700;
        font-family: "Open Sans";
        margin-bottom: 25px;

        & a {
            color: #00a748;
        }
    }

    &__authors {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        font-family: "Open Sans";
        display: flex;
        flex-direction: column;

        & li {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            line-height: 24px;
        }
    }

    &__workplace {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        font-family: "Open Sans";
    }
}

.Resume {
    padding: 20px 15px;
    background-color: #ecf2ec;
    margin-bottom: 30px;

    &__title {
        font-size: 20px;
        color: #000000;
        font-weight: 700;
        font-family: "Open Sans";
        margin-bottom: 30px;
    }

    &__maintext {
        font-size: 16px;
        color: #000000;
        font-family: "Open Sans";
        line-height: 24px;

        & div {
            margin-bottom: 24px;
        }

        & span {
            font-weight: bold;
        }

        & p {
            margin-bottom: 30px;
            white-space: pre-line;
        }
    }

    &__mainWords {
        & h6 {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            font-weight: 700;
            font-family: "Open Sans";
        }

        & p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            font-family: "Open Sans";
        }
    }
}

.articleData {
    width: 100%;
    &__maintext {
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        font-weight: 400;
        font-family: "Open Sans";
        margin-bottom: 25px;

        & i {
            font-style: italic;
        }

        & h5 {
            font-weight: bold;
        }

        & div {
            margin-bottom: 24px;
            line-height: 24px;
            white-space: pre-line;
            width: 100%;
        }

        & img {
            width: 100%;
        }
    }

    & h3 {
        font-size: 30px;
        line-height: 24pt;
        color: #0a0a0a;
        font-weight: 700;
        font-family: "Open Sans";
        margin-bottom: 16px;
    }
}

.References {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px;
    background-color: #ececf2;

    &__wrapper {
        margin-bottom: 50px;
    }

    &__text {
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        font-weight: 700;
        font-family: "Open Sans";
    }

    &__list {
        & li {
            margin-bottom: 10px
        }

        & li:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        width: 16px;
        height: 15px;
        // background: url('/img/css_sprites.png') -46px -10px;
        cursor: pointer;

        & img {
            width: 100%;
            height: 100%;
        }
    }
}

.goToStartPage {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #095930;
    font-weight: 400;
    font-family: "Open Sans";
    margin-bottom: 35px;
}

.Also {
    &__title {
        font-size: 24px;
        color: #000000;
        font-weight: 700;
        font-family: "Open Sans";
        margin-bottom: 24px;
    }

    & li {
        margin-bottom: 25px;
        position: relative;
        padding-left: 30px;

        & a {
            font-size: 14px;
            color: #000000;
            font-weight: 500;
            font-family: "Open Sans";
        }
    }

    & li:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-color: #095930;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 6px;
        left: 10px;
    }

    & li:last-child {
        margin-bottom: 0;
    }
}

.References__list {
    padding-top: 12px;
    padding: 12px;
    background-color: #FAFAFF;

    & div {
        margin-bottom: 24px;
    }

    & li {
        margin-bottom: 25px;
        position: relative;
        padding-left: 30px;

        & p {
            font-size: 14px;
            color: #000000;
            font-family: "Open Sans";
        }
    }

    // & li:before {
    //     content: '';
    //     display: block;
    //     border-radius: 50%;
    //     background-color: #095930;
    //     width: 6px;
    //     height: 6px;
    //     position: absolute;
    //     top: 6px;
    //     left: 10px;
    // }

    & li:last-child {
        margin-bottom: 0;
    }
}

.articlePage__aside {
    display: block;
    width: 100%;
    max-width: 300px;
    max-height: 600px;

    @media (max-width: 700px) {
        max-width: initial;
        width: 100%;
        margin-bottom: 40px;
    }
}

.articlePDF {
    width: 100%;
    height: 1150px;
    margin-bottom: 30px;
}

.pdfSection {
    .react-pdf__Document {
        display: flex;
        justify-content: center;
        overflow: scroll;
        box-shadow: -1px 0px 15px 2px rgba(0,0,0,0.55);
        margin-bottom: 20px;
    }

    .panel {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;

        .zoom {
            display: flex;
            justify-content: center;

            button {
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }
        }
    }

    .pages {
        display: flex;
        margin-right: 48px;

        button {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }

        span {
            margin-right: 10px;
            line-height: 32px;
        }
    }
}

.zoom-level-1 {
    .react-pdf__Page {
        transform: scale(1);
        transition: transform 0.5s;
        transform-origin: 0 0;
    }
}

.zoom-level-2 {
    .react-pdf__Page {
        transform: scale(1.2);
        transition: transform 0.5s;
        transform-origin: 0 0;
    }
}

.zoom-level-3 {
    .react-pdf__Page {
        transform: scale(1.4);
        transition: transform 0.5s;
        transform-origin: 0 0;
    }
}

.zoom-level-4 {
    .react-pdf__Page {
        transform: scale(1.6);
        transition: transform 0.5s;
        transform-origin: 0 0;
    }
}

.zoom-level-5 {
    .react-pdf__Page {
        transform: scale(1.8);
        transition: transform 0.5s;
        transform-origin: 0 0;
    }
}
