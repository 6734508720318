.AlpabetBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    & ul {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    & ul:last-child {
        margin-bottom: 0;
    }

    & ul li {
        margin-right: 10px;
    }

    & ul li a{
        font-size: 16px;
        line-height: 24px;
        color: #095930;
        font-weight: 600;
        font-family: "Open Sans";
    }
}

.AuthorsList {
    & li {
        width: 295px;
        margin-bottom: 20px;
    }

    & li a {
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        font-family: "Open Sans";
    }
}