.PrivacyPolicy {
    & h1 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    & h3 {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    & p {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 24px;
        font-family: 'Open sans';
    }

    & p a {
        color: black;
    }

    & ul {
        margin-bottom: 20px;
        margin-top: 20px;

        & li {
            line-height: 20px;
            margin-bottom: 5px;
            position: relative;
        }
    }

    & .markedList {
        margin-top: 10px;
        margin-left: 40px;

        & li {
            list-style-type: square;
        }

        & li::marker {
            color: #095930;
        }
    }
}